.App {
  display: grid;
  grid-template-rows: 60px 1fr 50px;
  min-height: 100vh;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 20px;
}

@media only screen and (max-width: 1000px) {
  .main {
    padding: 10px;
  }
}

@media only screen and (max-width: 500px) {
  .App {
    grid-template-rows: 55px 1fr 45px;
  }

  .main {
    padding: 5px;
  }
}